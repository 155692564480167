
.body{
    width: 100%;
    display: flex;
    min-height: 100vh;
    background: #FCFCFC;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.tabs{
  width: 60%;
    box-shadow: 0 4px 12px rgba(0,0,0,0.1);
    background: rgb(255,255,255);
    background: linear-gradient(331deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 66%, rgba(240,240,240,1) 100%);

}

.tabsHeader{
    width: 100%;
    display: flex;
    position: relative;
    align-items: center;
    background: white;
}

.tabLabel{
    position: relative;
    padding: 20px;
    width: 50%;
    cursor: pointer;
    transition: .3s ease-in-out color;
}

.tabIndicator{
    position: absolute;
    left: 0;
    top: 0;
    background: #2E5CD1;
    width: 50%;
    height: 100%;
}

.tab{
    width: 100%;
}

.tabsDisplay{
    overflow: hidden;
}

.tabsContainer{
    display: flex;
    width: 300%;
}

/*
Experience
*/

.ExperienceTileTitle{
    color: #1c1c1c;
    margin: 0;
    font-size: 20px;
}

.ExperienceTileDate{
    color: #1c1c1c;
    margin: 0;
    font-size: 14px;
}

.ExperienceTileHeader{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.ExperienceTileSubHeader > h4{
    margin: 0;
    letter-spacing: 2px;
    font-weight: 500;
    font-size: 16px;
}

.ExperienceTileContent > p {
    color: #585858;
    font-size: 14px;
    text-align: justify;
}

.ExperienceTile{
    margin: 40px;
    padding-bottom: 20px;
    border-bottom: 1px solid rgba(0,0,0,0.1);
}
.ExperienceTile:last-child{
    border: none;
    padding-bottom: 0;
}


.ExperienceTileContent > ul >li{
    padding: 3px 0;
    font-size: 14px;
}

.lower{
    display: none;
}

@media only screen and (max-width: 1400px) {
    .tabs{
        width: 90%;
        margin-top: 100px;
    }

    .ExperienceTileHeader{
        flex-direction: column;
        align-items: flex-start;
        padding: 10px 0;
    }

    .lower{
        display: flex;
    }
}