.body{
    width: 100%;
    display: flex;
    min-height: 100vh;
    background: #FCFCFC;
    justify-content: center;
    align-items: center;
}

.flexContainer{
    display: flex;
    max-width: 60%;
    box-shadow: 0 3px 10px rgba(0,0,0,0.1);
}

.container{
    background: white;
    padding: 50px;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.container:last-child{
    justify-content: space-between;
    background: rgb(255,255,255);
    background: linear-gradient(331deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 66%, rgba(240,240,240,1) 100%);
}

.container:first-child{
    background: rgb(46,92,209);
    background: linear-gradient(331deg, rgba(46,92,209,1) 0%, rgba(46,92,209,1) 66%, rgba(44,86,195,1) 100%);
}

h1{
    color: white;
}

h2{
    color: #ffffff;
    font-weight: 500;
    text-align: justify;
}

p{
    color: #d9d9d9;
    text-align: justify;
}

.icons{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.footer{
    text-align: center;
    padding: 20px 0;
}

.footer a {
    text-decoration: none;
    color: #585858;
}

/*
Button Styles
*/

.buttonContainer{
    background-color: #2E5CD1;
    padding: 20px;
    margin: 20px 0;
    border-radius: 30px;
    text-align: center;
    color: white;
    cursor: pointer;
}

/*
    IconLinks Styles
*/

.a{
    font-size: 50px;
    color: #1c1c1c;
    transition: .3s;
}

.a:hover{
    color: #2E5CD1;
}

/*
 Photo Styles
*/

.photo{
    height: 200px;
    width: 200px;
    background: grey;
    border-radius: 50%;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
}

.photo img{
    width: 100%;
    position: absolute;
    left: 0;
    top: -30px;
}

@media only screen and (max-width: 1400px) {

    .flexContainer{
        flex-direction: column;
        width: 90%;
        max-width: none;
        margin-top: 20px;
    }

    .container{
        width: calc(100% - 80px);
        padding: 40px;
    }

}

